<template>
    <FadeTransition :opened="opened">
        <div class="modal-backdrop">
            <div class="modal"
                role="dialog"
                aria-modal="true"
                :aria-labelledby="'modalTitle'+_.uid"
                :aria-describedby="'modalDescription'+_.uid"
                v-click-outside="closeClickOutside"
                @keyup.esc="close"
            >
                <header
                    class="modal-header"
                    :id="'modalTitle'+_.uid"
                >
                    <slot name="header">
                    </slot>

                    <button
                        type="button"
                        class="btn-close"
                        @click="close"
                        aria-label="Close modal"
                    >
                        x
                    </button>
                </header>
                <section
                    class="modal-body"
                    :id="'modalDescription'+_.uid"
                >
                    <slot name="body">
                    </slot>
                </section>
                <footer class="modal-footer">
                    <slot name="footer">
                    </slot>
                </footer>
            </div>
        </div>
    </FadeTransition>
</template>

<script>
import FadeTransition from './transitions/FadeTransition'

export default {
    name: 'Modal',
    components: {
        FadeTransition
    },
    props: {
        opened: Boolean,
        close_click_outside: {
            type: Boolean,
            default: () => false
        }
    },
    emits: ['close'],
    setup(props, {emit}){
        const close = () => emit('close');

        const closeClickOutside = () => {
            if(props.close_click_outside){
                close();
            }
        }

        return {
            close,
            closeClickOutside
        }
    }

}
</script>

<style>
 .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }

  .modal {
    background: #FFFFFF;
    box-shadow: 2px 2px 20px 1px;
    /* overflow-x: auto; */
    display: flex;
    flex-direction: column;
    margin: 3rem 0;
    max-height: calc(100vh - 6rem);
  }

  .modal-header,
  .modal-footer {
    padding: 0.8rem;
    display: flex;
  }

  .modal-header {
    border-bottom: 1px solid #eeeeee;
    color: var(--dark-color);
    justify-content: space-between;
    font-size: 1.0rem;
  }

  .modal-footer {
    border-top: 1px solid #eeeeee;
    justify-content: flex-end;
  }

  .modal-body {
    position: relative;
    padding: 0;
    overflow: auto;
    margin: 1rem;
  }

  .btn-close {
    font-size: inherit;
    border: none;
    padding: 0;
    cursor: pointer;
    font-weight: bold;
    color: var(--dark-color);
    background: transparent;
  }

  .btn-green {
    color: white;
    background: #4AAE9B;
    border: 1px solid #4AAE9B;
    border-radius: 2px;
  }

  .size-xl>.modal{
        width: 40%;
        min-width: 40rem;
        min-height: 40%;

  }
  
  .size-xxl>.modal{
        width: 50%;
        min-width: 50rem;

  }

@media screen and (max-width: 1200px) {
    .size-xl>.modal{
        width: 60%;
    }
    .size-xxl>.modal{
        width: 70%;
    }
}

  
@media screen and (max-width: 800px) {
    .size-xl>.modal,.size-xxl>.modal{
        width: 100%;
        min-width: 100%;
    }
    .modal-backdrop {
        display: block;
    }
}

    

</style>
