<template>
  <transition name="fade"  v-bind="$attrs">
        <slot v-if="opened"></slot>
  </transition>
</template>

<script>
export default {
    name: "FadeTransition",
    props: {
      opened: {
        type: Boolean,
        default: false
      }
    },
}
</script>

<style>
    .fade-enter,
    .fade-leave-active {
        opacity: 0;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity .5s ease
    }    
</style>