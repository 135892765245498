<template>
    <Modal class="files-upload-form" @close="closeForm()" :opened="opened">
        <template v-slot:header>
            {{ $t('cms.upload_new_files')}}
        </template>
        
        <template v-slot:body>
            <BaseForm  
                @closeForm="closeForm()" 
                :cancelButton="''"
                :deleteButton="false"
                :acceptButton="$t('cms.upload_files')" >
            </BaseForm>
        </template>

    </Modal>
</template>

<script>
import BaseForm from '@common/components/crud/BaseForm.vue'
import Modal from "@common/components/Modal";
import useFormData from '@common/composable/BaseFormData';
import { useI18n } from 'vue-i18n';
import { watchEffect } from 'vue';

export default {
    name: "FilesUploadForm",
    components: {
        BaseForm,
        Modal
    },
    emits: ['close'],
    props: {
        opened: Boolean,
    },  
    setup(props,context){
        const i18n = useI18n();          
        const { data,setRows,store_action,formInit,formActions } = useFormData();
        formInit();

        
        const rows = [
            {
                items: [
                    {
                        title: i18n.t('cms.files'),
                        type: 'file',
                        key: "files[]",
                        class: "w-stretch",
                    }
                ]
            },
        ];

        watchEffect(() => {
            if(props.opened){
                data.value = []
                store_action.value = 'file/uploadFiles';
                setRows(rows);
            }
        });

        
         const closeForm = () => {
            data.value = {};
            context.emit('close');
        }

        
        formActions.value.afterSubmit = () =>  closeForm();
        formActions.value.cancelForm = () =>  closeForm();
        formActions.value.deleteItem = () =>  closeForm();
        
        return {
            closeForm,
            data,
        }
    }  
}
</script>

<style scoped>
.base-form{
    width: 30rem;
    max-width: 100%;
}

.files-upload-form{
    z-index: 2100;
}

::v-deep(.col-input){
    width: 100%;
}
/* ::v-deep(.cell-wrap){
    height: 10rem;
} */
</style>